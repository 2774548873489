#ResumeContainer {
    margin:auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.PDFDocument {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PDFPage {
    margin-top: 2%;
    box-shadow: 0 0 8px rgba(0, 0, 0, .5);
}


.PDFPage > canvas {
    max-width: 100%;
    height: auto !important;
}